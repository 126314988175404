import React from 'react';
import styled from '@emotion/styled';

const AboutContainer = styled.div`
padding: 2rem;


li{
  padding-bottom: 1.5rem;
}
`
; 



const About: React.FC = () => {
  return (
    <AboutContainer>
      <h1>About BGhost</h1>
      <p>Welcome to <strong>Bghost.ai</strong>, the cutting-edge platform that is transforming video content with advanced AI-driven face swapping and voice cloning technology. Founded in August 2024, our mission is to empower creators, marketers, and businesses to redefine their video production by offering a seamless, innovative way to swap faces and clone voices in videos.</p>
      <p>At <strong>Bghost.ai</strong>, we leverage the power of AI to enhance creativity and streamline video production. Whether you're looking to replace a face, clone a voice, or add entirely new characters to your videos, our platform delivers high-quality, lifelike transformations with ease. We make video content more engaging, personalized, and versatile, while saving you time and effort in the process.</p>

      <h1>Our Vision</h1>
      <p>We envision a world where video creation is limited only by imagination. With the help of AI, we aim to break down traditional barriers in video production, making it easier for anyone—from individual creators to large companies—to customize and enhance their videos to perfection. Bghost.ai is here to enable the next generation of storytelling, marketing, and virtual interaction.</p>

      <h1>Our Approach</h1>
      <p>Since our founding in August 2024, Bghost.ai has focused on building a platform that’s intuitive, scalable, and powerful. We provide easy-to-use tools that allow users to seamlessly swap faces and clone voices, maintaining the highest level of realism and precision. Our technology is designed for everyone—whether you're creating personalized video messages, enhancing advertising campaigns, or developing professional content for films or media projects.</p>
      <p>We take security and ethical considerations seriously, ensuring that every transformation is carried out with the highest respect for privacy and user trust. Our commitment to high-quality results and innovation drives us to continually improve and refine our platform.</p>

      <h1>What We Do</h1>
      <ul>
      <li><strong>Face Swapping:</strong> Swap faces in your videos to create unique content, alter expressions, or replace actors with ease and realism.</li>
      <li><strong>Voice Cloning:</strong> Use our AI technology to clone voices, enabling you to replicate voices for localization, dubbing, or creating entirely new voiceovers.</li>
      <li><strong>User-Friendly Platform:</strong> Bghost.ai offers an easy-to-navigate interface, designed for anyone to use — no technical skills required.</li>
      <li><strong>Customizable Video Transformations:</strong> Whether for entertainment, business, or personal projects, our tools provide endless possibilities to transform your videos.</li>
      <li><strong>High-Quality Results:</strong> We guarantee smooth, high-quality video transformations, ensuring your content always meets the highest standards.</li>
      </ul>


      <h1>Why Choose Bghost.ai?</h1>
      <ul>
      <li><strong>Next-Generation AI:</strong> We offer state-of-the-art AI technology that delivers seamless and life like face swapping and voice cloning with stunning accuracy.</li>
      <li><strong>Unlimited Creativity:</strong> Transform your videos in ways that were once impossible, whether you’re creating new characters, personalizing messages, or enhancing marketing materials.</li>
      <li><strong>Speed and Efficiency:</strong> Our platform helps you save time and resources while delivering professional-grade results for all your video projects.</li>
      <li><strong>Ethical and Secure:</strong> We ensure that all your content and data are handled with the utmost care, upholding privacy and ethical standards at all times.</li>
      <li><strong>Scalable Solutions:</strong> Whether you’re working on one video or a large-scale project, Bghost.ai scales to meet your needs and grow with your ambitions.</li>
      </ul>

      <p>Founded in August 2024, <strong>Bghost.ai</strong> is committed to changing the future of video creation with AI-powered face and voice transformation. Unlock the full potential of your videos, and bring your creative vision to life like never before.</p>

    </AboutContainer>
  );
};

export default About;