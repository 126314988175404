import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { FaArrowRight } from 'react-icons/fa'; // Updated icons
import { FaUserGear, FaChartColumn, FaArrowRightFromBracket, FaCircleUser, FaFilm, FaRegStar   } from "react-icons/fa6";
import Modal from './Modal';
import SignInForm from './Login';
import Img from '../Img';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for programmatic navigation

const HeaderContainer = styled.header`
  background-color: ${props => props.theme.colors.background};
  padding: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: transform 0.5s ease-in-out;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

/* ---------- Left-side Nav Links ---------- */
const NavLink = styled(Link)`
  color: ${props => props.theme.colors.textLight};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  gap: 20px;
  border-radius: 100px;
  border: 1px solid #414141;
  background: #0C0C0C;
  padding: 0.5rem 1rem;
`;

const MenuLink = styled(Link)`
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  padding: 5px 15px;
  &:hover {
    background: #323232;
    border-radius: 15px;
  }
`;

/* ---------- Sign In Button (when not logged in) ---------- */
const SignButton = styled.button`
  border-radius: 100px;
  border: 1px solid #414141;
  background: #fff;
  width: 150px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  font-family: inherit;
  &:hover {
    background: ${props => props.theme.colors.hoverLightButton};
  }
`;

/* ---------- User Icon & Dropdown Menu (when logged in) ---------- */
const UserIconWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative; /* Ensures child dropdown uses this as its reference */
  cursor: pointer;
  background: #666;
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 18%;
  justify-content: center;
  font-weight: normal;
  font-size: 18px;
  text-transform: capitalize;

  &:hover > div {
    display: block;
  }
`;

/** The single-letter username inside the purple circle **/
const UserName = styled.span`
  pointer-events: none; /* So the hover area is the entire circle, not just the letter */
`;

/** The entire dropdown menu container **/
const UserMenu = styled.div`
  display: none;
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #0c0c0c;
  border-radius: 8px;
  width: 250px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 100; /* Ensure dropdown is over other content */
  padding: 10px;
`;

/** The top portion showing the user icon, name, and email **/
const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 15px;
  border-bottom: 1px solid #323232;
`;

/** Wrapper for the user name & email text **/
const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.2;
`;

const UserNameText = styled.div`
  font-weight: 600;
  font-size: 12px;
  font-variant: normal !important;
`;

const UserEmailText = styled.div`
  font-size: 12px;
  color: #bbb;
`;

/** Each clickable menu item **/
const MenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  padding: 8px 15px;
  cursor: pointer;
  position: relative;
  text-transform: capitalize; /* Make text lowercase */
  font-size: 14px; /* Default font size */
  
  &:first-letter {
    font-size: 18px; /* Larger first letter */
    font-weight: normal; /* Optional: Make the first letter bolder */
  }

  &:hover {
    background: #323232;
  }

  svg{
    flex-shrink: 0;
  }
`;

/** If you want a distinct style for Logout, you can extend MenuItem **/
const LogoutButton = styled(MenuItem)`
  /* Additional styling if needed */
`;

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const storedUserName = sessionStorage.getItem('username');
    const storedUserEmail = sessionStorage.getItem('useremail');
    if (storedUserName) {
      setUserName(storedUserName);
      setUserEmail(storedUserEmail || '');
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const handleProfiledashboard = () => {
    navigate('/dashboard');
  };
  const handleStudio = () => {
    navigate('/upload');
  };

  const handleProfileMenuClick = () => {
    navigate('/setting/profile');
  };
  const handlePricing = () => {
    navigate('/subscription');
  };

  const handleLogout = () => {
    sessionStorage.clear();
    window.location.href = '/'; // Or navigate('/') if you prefer
    setIsLoggedIn(false);
  };

  return (
    <HeaderContainer>
      <Nav>
        {/* Logo */}
        <NavLink to="/">
          <Img ImgName="logos/logo_white.png" alt="Logo" width={150} />
        </NavLink>

        {/* If logged in, show user circle & menu; otherwise show normal menu links + Sign In */}
        {isLoggedIn ? (
          <UserIconWrapper>
            <UserName>{userName[0]}</UserName> {/* First letter of user's name */}
            <UserMenu>
              <UserInfo>
              <FaCircleUser />
                <UserDetails>
                  <UserNameText>{userName}</UserNameText>
                  <UserEmailText>{userEmail}</UserEmailText>
                </UserDetails>
              </UserInfo>
              <MenuItem onClick={handleProfiledashboard}>
                <FaChartColumn />
                Dasboard
              </MenuItem>
              <MenuItem onClick={handleStudio}>
                <FaFilm />
                Studio
              </MenuItem>
              <MenuItem onClick={handleProfileMenuClick}>
                <FaUserGear />
                Profile
              </MenuItem>
              <MenuItem onClick={handlePricing}>
                <FaRegStar />
                Subscription
              </MenuItem>
              <LogoutButton onClick={handleLogout}>
              <FaArrowRightFromBracket />
                Log Out
              </LogoutButton>
            </UserMenu>
          </UserIconWrapper>
        ) : (
          <>
            <MenuContainer>
              <MenuLink to="/">Home</MenuLink>
              <MenuLink to="/about">About</MenuLink>
              <MenuLink to="/blog">Blogs</MenuLink>
              <MenuLink to="/price">Price</MenuLink>
              <MenuLink to="/community">Community</MenuLink>
            </MenuContainer>
            <SignButton onClick={() => setIsModalOpen(true)}>
              Sign In <FaArrowRight style={{ marginLeft: '8px', transform: 'rotate(-40deg)' }} />
            </SignButton>
          </>
        )}

        {/* Modal for Sign In */}
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <SignInForm />
        </Modal>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
