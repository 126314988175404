/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useState } from "react";
import { RiArrowRightCircleLine } from "react-icons/ri";
import PaymentTable from '../components/dashboard/PaymentTable'; // Make sure this path is correct


interface SubscriptionProps {
  onSignUpClick: () => void;
}

const Subscription: React.FC<SubscriptionProps> = ({ onSignUpClick }) => {
  const [isYearly, setIsYearly] = useState(false);

  return (
    <section id="price" css={SubscriptionStyle}>
      <h2 css={headingStyle}>
        Select the right plan to boost your marketing productivity
      </h2>

      {/* Toggle Button */}
      <div css={toggleButtonContainer}>
        <button
          css={[toggleButton, !isYearly && activeToggle]}
          onClick={() => setIsYearly(false)}
        >
          Monthly
        </button>
        <button
          css={[toggleButton, isYearly && activeToggle]}
          onClick={() => setIsYearly(true)}
        >
          Yearly
        </button>
      </div>

      <div css={pricingContainer}>
        {/* Basic Plan */}
        <div css={pricingCard}>
          <h3 css={planTitle}>Basic</h3>
          <h1 css={priceStyle}>FREE!</h1>
          <button css={signUpButton} onClick={onSignUpClick}>Sign up Now</button>
          <ul css={featuresList}>
            <li><RiArrowRightCircleLine /> 25 images or 1.5 mins of video</li>
            <li><RiArrowRightCircleLine /> 3 Customized Instant Avatars</li>
            <li><RiArrowRightCircleLine /> Upload file size limited to 150M and 30s</li>
            <li><RiArrowRightCircleLine /> 60+ free Public Studio Avatars</li>
            <li><RiArrowRightCircleLine /> Upload quality limited to 720P</li>
          </ul>
        </div>

        {/* Pro Plan */}
        <div css={pricingCard}>
          <h3 css={planTitle}>Pro</h3>
          <h1 css={priceStyle}>{isYearly ? "$40" : "$50"} /Mn</h1>
          <button css={buyButton}>Buy Credits</button>
          <ul css={featuresList}>
            <li><RiArrowRightCircleLine /> Everything in Basic</li>
            <li><RiArrowRightCircleLine /> Watermark Removal</li>
            <li><RiArrowRightCircleLine /> 5 Customized Instant Avatars</li>
            <li><RiArrowRightCircleLine /> Upload file size up to 300M and 5 mins</li>
            <li><RiArrowRightCircleLine /> Upload quality up to 1080P</li>
            <li><RiArrowRightCircleLine /> Unlimited Voice Clone</li>
            <li><RiArrowRightCircleLine /> Fast Processing</li>
          </ul>
        </div>
      </div>

      {/* Payment History Section */}
      <div css={PaymentListBox}>
      <h1>Payment History</h1>
        <PaymentTable />
      </div>

    </section>
  );
};

// Emotion CSS styles

const SubscriptionStyle = css`
  padding: 50px;
  text-align: center;
  color: #fff;
`;

const headingStyle = css`
  font-size: 2.4rem;
  margin-bottom: 30px;
  color: #111;
`;

const toggleButtonContainer = css`
  display: inline-flex;
  border-radius: 30px;
  border: 2px solid #fff;
  overflow: hidden;
  margin-bottom: 40px;
`;

const toggleButton = css`
  padding: 10px 30px;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  background-color: #818181;
  color: #fff;
`;

const activeToggle = css`
  background-color: #1a1a1a;
  color: #fff;
`;

const pricingContainer = css`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const pricingCard = css`
  background-color: #1a1a1a;
  padding: 30px;
  border-radius: 10px;
  width: 300px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
  text-align: center;
  border: 1px solid #333;

  @media (max-width: 768px) {
    width: 100%;
    padding: 15px;
  }
`;

const planTitle = css`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const priceStyle = css`
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

const signUpButton = css`
  background-color: #333;
  color: #fff;
  border: none;
  padding: 12px 24px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 30px;
`;

const buyButton = css`
  background-color: #fff;
  color: #111;
  border: none;
  padding: 12px 24px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 30px;
`;

const featuresList = css`
  list-style: none;
  padding: 0;
  text-align: left;
  color: #fff;
  font-size: 0.9rem;

  li {
    margin-bottom: 10px;
  }
`;
const PaymentListBox = css`
  color: #000;
  text-align: center;

h1{
margin-top:50px;
padding-top:30px;
padding-bottom:30px;
border-bottom: 1px solid #d7d7d7;
border-top: 1px solid #d7d7d7;
}

`;

export default Subscription;
