import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainLayout from '../components/Layout/MainLayout';
import Home from '../pages/Home';
import About from '../pages/About';
import VideoUploadPage from '../pages/UploadVideo';
import Result from '../pages/result';
import ProfilePage from '../pages/ProfilePage';
import Subscription from '../pages/Subscription';
import Dashboard from '../pages/Dashboard';
import ProtectedUploadRoute from '../pages/ProtectedUploadRoute';
import Community from '../pages/Community';
import BlogPage from '../pages/blogs';
import BlogDetails from '../pages/blogs/BlogDetails';

const AppRoutes: React.FC = () => {
  // Define the handleSignUpClick function
  const handleSignUpClick = () => {
    // Handle sign-up logic here
    console.log("Sign up button clicked!");
  };

  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />

        {/* New routes for the multi-step video editing process */}
        <Route path="/upload" element={<ProtectedUploadRoute />} />
        <Route path="/result" element={<Result />} />

        <Route path="/setting/profile" element={<ProfilePage />} />

        <Route path='/dashboard' element={<Dashboard />} />
        <Route path="/community" element={<Community />} />
        
        {/* Subscription route with onSignUpClick handler */}
        <Route 
          path='/subscription' 
          element={<Subscription onSignUpClick={handleSignUpClick} />} 
        />

        {/* Blog routes */}
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:title" element={<BlogDetails />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
