import React from 'react';
import styled from '@emotion/styled';
import VideoList from '../components/dashboard/VideoList';
import PaymentTable from '../components/dashboard/PaymentTable';
import { Link } from 'react-router-dom';

const Container = styled.div` padding-top: 0.5rem; `;
const Title = styled.h2` margin-top:50px;
padding-top:30px;
padding-bottom:30px;
border-bottom: 1px solid #d7d7d7;
border-top: 1px solid #d7d7d7; `;

const Dashboard: React.FC = () => {
  return (
    <Container>
      <h1>Dashboard <Link to="/upload" className="btn btn-primary custom-btn float-right">Studio</Link></h1>
      <Title>Recent work</Title>
      <VideoList />
      <Title>Payment History</Title>
      <PaymentTable />
    </Container>
  );
};

export default Dashboard;
